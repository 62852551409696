import { render, staticRenderFns } from "./TagsOfRecommendation.vue?vue&type=template&id=4b5bdeb8&scoped=true"
import script from "./TagsOfRecommendation.vue?vue&type=script&lang=js"
export * from "./TagsOfRecommendation.vue?vue&type=script&lang=js"
import style0 from "./TagsOfRecommendation.vue?vue&type=style&index=0&id=4b5bdeb8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b5bdeb8",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BCollapse} from 'bootstrap-vue'
    installComponents(component, {BButton, BCollapse})
    

export default component.exports
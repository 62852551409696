<template>
	<svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M20.1275 13.6357C20.1758 13.2732 20.2 12.8987 20.2 12.4999C20.2 12.1132 20.1758 11.7266 20.1154 11.3641L22.5683 9.4549C22.7858 9.28574 22.8463 8.95949 22.7133 8.71782L20.3933 4.70615C20.2483 4.44032 19.9463 4.35574 19.6804 4.44032L16.7925 5.60032C16.1883 5.14115 15.5479 4.75449 14.835 4.46449L14.4 1.39532C14.3517 1.10532 14.11 0.899902 13.82 0.899902H9.18C8.89 0.899902 8.66042 1.10532 8.61208 1.39532L8.17708 4.46449C7.46417 4.75449 6.81167 5.15324 6.21958 5.60032L3.33167 4.44032C3.06583 4.34365 2.76375 4.44032 2.61875 4.70615L0.310834 8.71782C0.165834 8.97157 0.214167 9.28574 0.455834 9.4549L2.90875 11.3641C2.84833 11.7266 2.8 12.1253 2.8 12.4999C2.8 12.8745 2.82417 13.2732 2.88458 13.6357L0.431667 15.5449C0.214167 15.7141 0.15375 16.0403 0.286667 16.282L2.60667 20.2937C2.75167 20.5595 3.05375 20.6441 3.31958 20.5595L6.2075 19.3995C6.81167 19.8587 7.45208 20.2453 8.165 20.5353L8.6 23.6045C8.66042 23.8945 8.89 24.0999 9.18 24.0999H13.82C14.11 24.0999 14.3517 23.8945 14.3879 23.6045L14.8229 20.5353C15.5358 20.2453 16.1883 19.8587 16.7804 19.3995L19.6683 20.5595C19.9342 20.6562 20.2363 20.5595 20.3813 20.2937L22.7013 16.282C22.8463 16.0162 22.7858 15.7141 22.5563 15.5449L20.1275 13.6357ZM11.5 16.8499C9.1075 16.8499 7.15 14.8924 7.15 12.4999C7.15 10.1074 9.1075 8.1499 11.5 8.1499C13.8925 8.1499 15.85 10.1074 15.85 12.4999C15.85 14.8924 13.8925 16.8499 11.5 16.8499Z" :fill="fill" />
	</svg>
</template>

<script>
export default {
	name: 'icon-settings',
	props: {
		fill: {
			type: String,
			default: 'black',
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
    cursor: pointer;
}
</style>

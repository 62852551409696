<template>
	<div class="row">
		<b-collapse
			id="collapse-recommendations"
			:visible="isVisible"
			class="col-12 mt-2"
			role="tabpanel"
			@input="$emit('setVisible', isVisible)"
		>
			<div class="recommendations-tags">
				<!-- <b-button
					:variant="selectedTags.includes(button.id) ? 'primary' : 'outline-primary'"
					class="d-flex flex-row align-items-center text-left mr-4 mt-2"
					@click="selectTag(button.id)"
				>
					Все
				</b-button> -->
				<b-button
					v-for="button in buttons"
					:key="button.id"
					:variant="selectedTags.includes(button.id) ? 'primary' : 'outline-primary'"
					class="d-flex flex-row align-items-center text-left mr-1 mr-md-3 mt-2"
					@click="selectTag(button.id)"
				>
					{{ button.name_ru }}
				</b-button>
			</div>
		</b-collapse>
	</div>
</template>

<script>

export default {
	name: 'TagsOfRecommendation',
	mixins: [],
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			buttons: [],
			selectedTags: [],
		};
	},
	watch: {
		'$auth.user': {
			async handler(user) {
				if (user) {
					await this.getUserRecomendations();
				}
			},
			immediate: true,
		},
	},
	async created() {
		const { data } = await this.$api.getTagsRecommendations();
		this.buttons = data;
	},
	methods: {
		async getUserRecomendations() {
			const { data: values } = await this.$api.getUserProfileFields('slugs[]=user_recommendations');
			if (values.user_recommendations) {
				this.selectedTags = values.user_recommendations.map((el) => +el);
				this.$emit('setIsAutocompleted', true);
			}
			this.$emit('input', this.selectedTags);
		},
		selectTag(tag) {
			if (this.selectedTags.includes(tag)) {
				this.selectedTags = this.selectedTags.filter((el) => el !== tag);
			} else {
				this.selectedTags.push(tag);
			}
			this.$emit('input', this.selectedTags);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~/assets/styles/sk_variables';
@import "~/assets/styles/bootstrap/scss/functions";
@import "~/assets/styles/bootstrap/scss/variables";
@import "~/assets/styles/bootstrap/scss/mixins";

.recommendations-tags {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;

	@include media-breakpoint-down(sm) {
		button {
			font-size: 0.7rem;
			padding: 0.375rem 0.5rem;
		}
	}
}
</style>
